// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap';

(function () {

  var isMenuExpanded = false;

  // fix dropdown menu placement on main nav
  function repositionMenu() {
    if($(window).width() >= 768) {
      $('nav.main-nav').find('ul>li>ul').each(function(i, el){
        $(el).css('margin-left', $(el).parent().width()/2);
      });
    }
  }

  $(window).resize(function(){
    if($(window).width() >= 768) {
      $('nav.main-nav').removeClass('expanded');
      isMenuExpanded = false;

      repositionMenu();
    }
  });

  $('nav.main-nav .hamburger').on('click', function(){
    if($(window).width() < 768) {
      if(isMenuExpanded){
        $('nav.main-nav').removeClass('expanded');
        isMenuExpanded = false;
      } else {
        $('nav.main-nav').addClass('expanded');
        $('nav.main-nav').find('ul>li>ul').each(function(i, el){
          $(el).css('margin-left', 0);
        });
        isMenuExpanded = true;
      } 
    }
  });

  // homepage slider
  var slider = function(target){

    var options = {
      slideDuration: 15
    };

    var panes = [];

    var counter = 0;

    var timer = null;

    var init = function (el){
      el = $(el);

      panes = el.find('.pane');

      panes.each(function(i, el){
        if(i == 0){ return; }
        $(el).addClass('hideMe');
      });

      function timerFunc(){
        var current = $(panes[counter]);
        counter = (counter + 1 >= panes.length) ? 0 : counter + 1;

        var next = $(panes[counter]);
        current.removeClass('showMe').addClass('hideMe');
        next.removeClass('hideMe').addClass('showMe');

        timer = setTimeout(timerFunc, (options.slideDuration * 1000));
      }

      timer = setTimeout(timerFunc, (options.slideDuration * 1000));
    };

    init(target);
  };

  // init slider
  slider('#slider');

  // fabric processing form
  $('#fabric-processing-table button').bind('click', function(){
    alert('form logic required');
    return false;
  });
})();